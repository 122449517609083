import { useState } from 'react'

import styles from './index.module.scss'

import reskillingBannerPc from '../../public/reskilling_banner_pc.png'
import reskillingBannerPcDetail from '../../public/reskilling_banner_detail_pc.png'
import reskillingPriceDescriptionPcDetail from '../../public/reskilling_price_description_detail_pc.png'

import reskillingBannerSp from '../../public/reskilling_banner_sp.png'
import reskillingBannerSpDetail from '../../public/reskilling_banner_detail_sp.png'
import reskillingPriceDescriptionSpDetail from '../../public/reskilling_price_description_detail_sp.png'

type Props = {
  isPc: boolean
}

export const AwardContentReskilling = ({ isPc }: Props) => {
  const [showReskillingBannerDetail, setShowReskillingBannerDetail] =
    useState<boolean>(false)
  const [showPriceDescriptionDetail, setShowPriceDescriptionDetail] =
    useState<boolean>(false)

  const toggleReskillingBannerDetail = () => {
    setShowReskillingBannerDetail(!showReskillingBannerDetail)
  }

  const togglePriceDescriptionDetail = () => {
    setShowPriceDescriptionDetail(!showPriceDescriptionDetail)
  }

  return (
    <div className={styles.container}>
      <div className={styles.reskiliingContainer}>
        <div
          className={`${styles.showDetailToggle} ${
            showReskillingBannerDetail
              ? styles.reskillingBannerDetailOpen
              : styles.reskillingBannerDetailClose
          }`}
          onClick={toggleReskillingBannerDetail}
        ></div>
        {showReskillingBannerDetail ? (
          <>
            <a
              className={styles.linkToPricePageText}
              href="https://shelikes.jp/landing_pages/price"
              target="_blank"
              rel="noreferrer"
            ></a>
            <div
              className={`${styles.showPriceDescriptionDetailToggle} ${
                showPriceDescriptionDetail
                  ? styles.priceDescriptionDetailOpen
                  : styles.priceDescriptionDetailClose
              }`}
              onClick={togglePriceDescriptionDetail}
            ></div>
            {showPriceDescriptionDetail ? (
              <img
                src={
                  isPc
                    ? reskillingPriceDescriptionPcDetail.src
                    : reskillingPriceDescriptionSpDetail.src
                }
                alt=""
              />
            ) : (
              <img
                src={
                  isPc
                    ? reskillingBannerPcDetail.src
                    : reskillingBannerSpDetail.src
                }
                alt=""
              />
            )}
          </>
        ) : (
          <img
            src={isPc ? reskillingBannerPc.src : reskillingBannerSp.src}
            alt=""
          />
        )}
      </div>
    </div>
  )
}
