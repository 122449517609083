import React from 'react'
import styles from './index.module.scss'
import HeaderContainerBg from '../../public/reservation_form_mv_bg-pc.png'
import ReservationFormMvPc from '../../public/reservation_form_mv-pc.png'
import ReservationFormMvSp from '../../public/reservation_form_mv-sp.png'
import ReservationMoveButton from '../../components/ReservationMoveButton'
import { ContentType } from '../../types/trialLessons'
import { THRESHOLD_SP_WIDTH } from '../../utils/constants'

const TrialHeader = ({
  isShowCTA = true,
  contentType,
  label,
  isCountdownActive,
}: {
  isShowCTA?: boolean
  contentType?: ContentType
  label?: string
  isCountdownActive?: boolean
}) => {
  return (
    <>
      <section
        className={styles.headerContainer}
        style={{
          backgroundImage: `url(${HeaderContainerBg.src})`,
          marginTop: isCountdownActive ? 44 : 0,
        }}
      >
        <div className={styles.heroWrapper}>
          <picture>
            <source
              media={`(max-width: ${THRESHOLD_SP_WIDTH}px)`}
              srcSet={ReservationFormMvSp.src}
            />
            <img
              src={ReservationFormMvPc.src}
              alt="SHE likes MUTI CREATOR COURSE DESIGNER 無料体験レッスン予約フォーム"
            />
          </picture>
        </div>
        {isShowCTA && (
          <ReservationMoveButton
            contentType={contentType}
            moveToId="reservation-step-summary"
            label={label}
          />
        )}
      </section>
    </>
  )
}

export default TrialHeader
