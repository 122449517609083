import * as React from 'react'
import styles from './index.module.scss'
import { UpdateActionButton } from '../LessonListActionButton'
import { LessonTypeSummaryCard } from '../LessonTypeSummaryCard'
import { LessonTypeId } from '../LessonListContent'

export const SelectedLessonTypeSummary2 = ({
  selectedLessonTypeId,
  onUnselect,
}: {
  selectedLessonTypeId?: LessonTypeId
  onUnselect: () => void
}) => {
  return (
    <div className={styles.selectedAreaWrapper}>
      {selectedLessonTypeId && (
        <LessonTypeSummaryCard
          lessonTypeId={selectedLessonTypeId}
          actionButton={<UpdateActionButton onClick={onUnselect} />}
          // className={styles.areaCard}
        />
      )}
    </div>
  )
}
