import React, { ReactNode } from 'react'
import classNames from 'classnames'
import styles from './radioCard.module.scss'
import RadioCheckedSvg from '../../public/svgs/radio-checked.svg'
import RadioBlankSvg from '../../public/svgs/radio-blank.svg'

type RadioButtonProps = {
  checked?: boolean
  onCheck?: (checked: boolean) => void
  className?: string
  styleName?: string
}

export const RadioButton = ({
  checked,
  onCheck,
  className = undefined,
}: RadioButtonProps) => (
  <div className={styles.radioWrapper}>
    <img
      className={classNames(styles.radio, { checked: checked })}
      width={16}
      height={16}
      onClick={onCheck !== undefined ? () => onCheck(!checked) : undefined}
      src={checked ? RadioCheckedSvg.src : RadioBlankSvg.src}
    />
  </div>
)

type Props = {
  id?: string
  checked: boolean
  label: string
  onCheck: (checked: boolean) => void
  widthType?: 's' | 'm' | 'l'
  className?: string
  'data-value'?: string
  isRecommended?: boolean
  description?: ReactNode
}

export const RadioCard = ({
  id,
  label,
  checked,
  onCheck,
  widthType = 'm',
  'data-value': dataAttribute,
  description,
  className,
}: Props) => (
  <div
    id={id}
    className={`${className} ${classNames(styles.radioCard, styles.m, {
      [styles.checked]: checked,
    })}`}
    // className={className}
    onClick={() => onCheck(!checked)}
    data-value={dataAttribute}
  >
    <div className={styles.label}>
      <RadioButton
        checked={checked}
        onCheck={onCheck}
        className={styles.radio}
      />
      {label}
    </div>
    {description && <div className={styles.description}>{description}</div>}
  </div>
)
