import * as React from 'react'
import styles from './index.module.scss'
import { LessonTypeId, getLessonTypeSpec } from '../LessonListContent'

type ItemProps = {
  lessonTypeId: LessonTypeId
  actionButton: JSX.Element
}

export const LessonTypeSummaryCard = ({
  lessonTypeId,
  actionButton,
}: ItemProps) => {
  if (!lessonTypeId) {
    return null
  }
  return (
    <div
      id={`type-summary-card-${lessonTypeId}`}
      className={styles.typeSummaryCard}
    >
      <div className={styles.header}>
        <div>{getLessonTypeSpec(lessonTypeId)?.label}</div>
        {actionButton}
      </div>
    </div>
  )
}
