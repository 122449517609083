import React, { useCallback } from 'react'
import styles from './index.module.scss'
import { ContentType } from '../../types/trialLessons'

type Props = {
  moveToId?: string
  contentType?: ContentType
  label?: string
}

const LABEL = {
  onlyOtl: '無料人気コース体験',
  crm: '無料人気コース体験',
  recommendOns: '無料少人数相談会',
  normal: '人気のWEBスキル講座',
}

const ReservationMoveButton = ({
  moveToId = 'reservation-step-summary',
  contentType,
  label,
}: Props) => {
  const onClick = useCallback(() => {
    const moveTo = window.document
      .getElementById(moveToId)
      ?.getBoundingClientRect().top as number
    const scrollY = window.pageYOffset
    window.scroll({
      top: moveTo - scrollY,
      behavior: 'smooth',
    })
  }, [moveToId])

  return (
    <button className={styles.container} onClick={onClick} type="button">
      <p className={styles.text}>{label || '日程を選択する'}</p>
      <div className={styles.svg}>
        <svg
          width="12"
          height="6"
          viewBox="0 0 12 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.99996 6L-5.24537e-07 1.04907e-06L12 0L5.99996 6Z"
            fill="white"
          />
        </svg>
      </div>
      <p className={styles.label}>
        {LABEL[contentType as ContentType] || LABEL.normal}
      </p>
    </button>
  )
}

export default ReservationMoveButton
