import React, { ReactNode } from 'react'

import classNames from 'classnames'
import styles from './checkBoxCardSelectInput.module.scss'
import { RadioCard } from './RadioCard'
import { ContentType, TrialLessonArea } from '../../types/trialLessons'

type OptionSpec = {
  label: string
  recommendLabel: string
  sheGirl: string
  headerText: string
  description?: ReactNode
  badge?: string
  value: string
  htmlDataValue?: string
  isRecommended?: boolean
}

type SingleProps = {
  options: OptionSpec[]
  value?: string | null
  widthType?: 's' | 'm' | 'l'
  onChange: (value: any) => void
  className?: string
  styleName?: string
  contentType: ContentType
  arrangementType?: 'horizon' | 'vertical'
  gaSendEventCategory?: string
  gaSendEventEventName?: string
  addGaSendEventEventNameDetail?: boolean
}

export const CheckBoxCardSelectInput = ({
  value,
  options,
  onChange,
  widthType = 'l',
  arrangementType = 'vertical',
  contentType,
  gaSendEventCategory,
  gaSendEventEventName,
  addGaSendEventEventNameDetail,
}: SingleProps) => {
  return (
    <div className={styles.selectInput}>
      {options.map((o, i) => (
        <div
          key={String(o.value)}
          className={classNames(styles.cardWrapper, {
            [styles.cardForHorizon]: arrangementType === 'horizon',
            [styles.normal]: contentType === 'normal',
            [styles.recommendOns]: contentType === 'recommendOns',
          })}
        >
          {i === 0 && (
            <img
              className={styles.recommendLabel}
              src={o.recommendLabel}
              alt=""
            />
          )}
          <div
            className={`${styles.cardHeader} ${i === 1 && styles.rowReverse}`}
          >
            <img className={styles.sheGirl} src={o.sheGirl} alt="" />
            <p className={styles.cardHeaderText}>{o.headerText}</p>
          </div>
          <RadioCard
            checked={o.value === value}
            className={classNames(styles.card, {
              [styles.cardForHorizon]: arrangementType === 'horizon',
            })}
            label={o.label}
            onCheck={checked => {
              onChange(checked ? (o.value as TrialLessonArea) : null)
              // TODO implement GA
              // gaSendEventCategory &&
              // gaSendEventEventName &&
              // GoogleAnalyticsClient.sendGaEvent(
              //   gaSendEventCategory,
              //   addGaSendEventEventNameDetail ? gaSendEventEventName + '_' + o.value : gaSendEventEventName
              // );
            }}
            data-value={o.htmlDataValue}
            description={o.description}
          />
        </div>
      ))}
    </div>
  )
}
