import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'

import styles from './index.module.scss'

import { ContentType } from '../../types/trialLessons'
import { THRESHOLD_DESKTOP_WIDTH } from '../../utils/constants'
import { AwardContentOns } from '../AwardContentOns'
import { AwardContentOtl } from '../AwardContentOtl'
import { useRouter } from 'next/router'

type Props = {
  contentType: ContentType
}

const RESKILLING_SOURCES: string[] = ['deca', 'karte', 'lks']

export const AwardContent = ({ contentType }: Props) => {
  const [showReskilling, setShowReskilling] = useState<boolean>(false)

  const router = useRouter()
  const isOns = contentType.includes('Ons')
  const isPc = useMediaQuery(`(min-width:${THRESHOLD_DESKTOP_WIDTH}px)`)

  useEffect(() => {
    if (router?.isReady) {
      const utm_source = router.query.utm_source
      if (RESKILLING_SOURCES.some(s => s === utm_source)) {
        setShowReskilling(true)
      }
    }
  }, [router])

  return (
    <section id="award-content" className={styles.container}>
      {isOns ? (
        <AwardContentOns isPc={isPc} showReskilling={showReskilling} />
      ) : (
        <AwardContentOtl isPc={isPc} showReskilling={showReskilling} />
      )}
    </section>
  )
}
